/* You can add global styles to this file, and also import other style files */
html,
body {
    margin: 0;
    padding: 0;
}

body {
    background-color: rgb(120, 120, 120, 40);
}

.mycard {
    max-width: 800px;
    margin: auto;
}

.mg-5 {
    margin: 5px;
}

.botones {
    margin: 2em 2em 0 2em;
    display: flex;
    justify-content: space-between;
}

.bordes {
    border: solid .01rem rgb(120, 120, 120, 30%);
    padding: 1rem;
    border-radius: 2rem;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
}